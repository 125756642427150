import { CheckCircleIcon } from '@heroicons/react/outline';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import Blurb from '~components/common/Blurb';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import CourseButton from '~components/common/CourseButton';
import FaqItem from '~components/common/FaqItem';
import FeatureList from '~components/common/FeatureList';
import GallerySlider from '~components/common/GallerySlider';
import GridThree from '~components/common/GridThree';
import GridTwo from '~components/common/GridTwo';
import ImageVideo from '~components/common/ImageVideo';
import TestmonialsSlider from '~components/common/TestmonialsSlider';
import Layout from '~components/layouts/Default';
import Cta from '~components/sections/Cta';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

function Page({ location, data }) {
  const features = [];
  data.kurse.features.forEach((feature) => {
    features.push({
      gray: feature.field.label === 'Ausgegraut (nicht verfügbar)' ? true : false,
      text: feature.value
    });
  });

  const faq = [
    {
      text: '<p>Das Autogene Training ist eines der zwei gro&szlig;en Entspannungsverfahren, die in Westeuropa am weitesten erforscht sind. Die positiven Auswirkungen sind wissenschaftlich belegt. Das andere Verfahren ist die Progressive Muskelentspannung. Auch mit dieser Methode machen &Auml;rzte, Psycho- aber auch Physiotherapeuten gute Fortschritte in der Arbeit mit ihren Patienten. Die Wirksamkeit ist daher unbestritten.&nbsp;</p>',
      id: '6077ec2083750949b741fc61',
      frage: 'Bringen solche Verfahren wirklich was?'
    },
    {
      text: '<p>Die beste Wirkung wird nat&uuml;rlich mit dem regelm&auml;&szlig;igen &uuml;ben eintreten. Es empfiehlt sich immer wieder dieselbe Zeit und denselben Ort einzunehmen. Wir empfehlen, zwei bis dreimal pro Woche zu &uuml;ben. Allerdings ist es auch nicht schlimm, wenn du es mal nicht schaffst zu trainieren. Daf&uuml;r hast du schlie&szlig;lich die begleiteten Kursstunden. Einmal richtig erlernt, kannst du es dein ganzes Leben lang nutzen.</p>',
      id: '6077ec2b922e814b790eac43',
      frage: 'Wie lange muss ich pro Tag üben?'
    },
    {
      text: '<p>Die Kurse Progressive Muskelentspannung &amp; Autogenes Training sind nach &sect;20 SGB V von der Zentralen Pr&uuml;fstelle der Pr&auml;vention zertifiziert und werden von den gesetzlichen Krankenkassen bezuschusst.</p>\n<p>Kurs-<strong>ID 20200807-1221034</strong>&nbsp;(Onlinekurs Autogenes Training) | Zentrale Pr&uuml;fstelle Pr&auml;vention.<br />Kurs-<strong>ID 20200625-12</strong><strong>9838</strong>&nbsp;(Onlinekurs Progressive Muskelentspannung) | Zentrale Pr&uuml;fstelle Pr&auml;vention.</p>',
      id: '6077ec468be6012a8800aef1',
      frage: 'Bezuschussen die Krankenkassen die Kurse?'
    },
    {
      text: '<p>Nein! Du kannst die Kurse buchen und die Teilnahmebescheinigung nach Abschluss bei der Krankenkasse einreichen. Die Bezuschussung von ca. 75&euro; &ndash; 150&euro; ist in der Regel innerhalb von 14 Tagen nach Abschluss des Kurses auf deinem Konto.</p>',
      id: '6077ec53afc98102f2507eb1',
      frage: 'Muss ich die Kurse vor Buchung bei der Krankenkasse anfragen?'
    },
    {
      text: '<p>Die Bezuschussung betr&auml;gt zwischen ca. 75&euro; &ndash; 150&euro; der Kosten. F&uuml;r eine genaue Aussage kontaktiere bitte deine Krankenkasse, da es hier individuelle Unterschiede gibt.</p>',
      id: '6077ec5d8be6012a8800aef3',
      frage: 'Wie hoch fällt die Krankenkassenförderung aus?'
    },
    {
      text: '<p>Kein Stress! Du kannst deinen Kurs insgesamt in Summe 4 Wochen aufschieben.</p>',
      id: '6077ec688be6012a8800aef5',
      frage: 'Was passiert , wenn ich krank  oder im Urlaub bin während des Kurses?'
    },
    {
      text: '<p>Du kannst die Kurse so oft buchen wie du m&ouml;chtest. Eine Krankenkassenf&ouml;rderung steht dir jedoch nur f&uuml;r zwei Kurse pro Kalenderjahr zur Verf&uuml;gung.</p>',
      id: '6077ec718be6012a8800aef7',
      frage: 'Wie viele Kurse darf ich Buchen?'
    }
  ];

  return (
    <Layout
      location={location}
      title="Stressmanagement als Werkzeugkoffer für mehr Gelassenheit"
      desc="✅ Eigene Ressourcen im Blick halten, Grenzen setzen, Nein sagen und Entspannung sind Bestandteile guten Stressmanagements, die du bei uns lernst."
      active="Autogenes Training"
      green
    >
      <PageHeader title={data.kurse.title} image={data.bildKurs} classNameImage="max-w-md animate-shake-vertical" wrapperClass="max-w-5xl mx-auto">
        {data.kurse.desc && <P>{data.kurse.desc}</P>}
        <p className="font-display">
          <span className="text-5xl">{data.kurse.price} €</span>
          {/* space */}
          <span className="text-base"> /{data.kurse.frequenz} inkl USt.</span>
        </p>
        <div className="py-8">
          <h3 className="mb-3 font-display font-medium">Beinhaltet:</h3>
          <FeatureList items={features} className="space-y-5" />
        </div>
        <CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />
      </PageHeader>

      <Container>
        <H2 className="mb-16 text-center font-display">Warum du Stressmanagement lernen solltest:</H2>
        <dl>
          <GridThree>
            <Blurb
              name="Leistungsfähiger werden"
              description="Durch Strategien aus dem Zeit- und Selbstmanagement verbesserst du deine Leistungsfähigkeit und sorgst so in weniger Zeit für bessere Ergebnisse."
              icon={<CheckCircleIcon className="h-12 w-12" />}
            />
            <Blurb name="Mehr Lebensenergie" description="Du lernst Methoden, um deinen Energielevel auf einem guten Level zu halten und teilst dir deine Aufgaben zielgerichtet ein. " icon={<CheckCircleIcon className="h-12 w-12" />} />
            <Blurb
              name="Höhere Lebensqualität"
              description="Du gewinnst die Kontrolle über dein Leben zurück, setzt Grenzen und lernst Nein zu sagen. Ab sofort achtest du auf deine Bedürfnisse und machst Dinge, die dir wichtig sind."
              icon={<CheckCircleIcon className="h-12 w-12" />}
            />
          </GridThree>
        </dl>
      </Container>
      <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} />
      <Container>
        <GridTwo>
          <div className="relative grid grid-cols-1 gap-4 rounded-xl bg-white p-6 text-brand-gray md:grid-cols-1 lg:grid-cols-2">
            <div>
              <H2 className="mb-5 font-display">Was du hier lernst:</H2>
              <FeatureList
                items={[
                  { text: 'Was ist Stressmanagement?' },
                  { text: 'Der Stressbegriff und seine Herkunft' },
                  { text: 'Vorteile effektiven Stressmanagements' },
                  { text: 'Unsere drei Lieblingstipps gegen Stress' },
                  { text: 'Wie du zum Stressmanager wirst' },
                  { text: 'Drei Formen des Stressmanagements' }
                ]}
                className="space-y-5"
              />
            </div>
            <div>
              <GatsbyImage image={getImage(data.bildJohannes)} alt="Autogenes Training - Dein Held Johannes" title="Autogenes Training - Dein Held Johannes" className="rounded-xl" />
            </div>
          </div>
          <div className="rounded-xl bg-white p-6 text-brand-gray">
            <H2 className="mb-5 font-display">Was unsere Absolventen sagen:</H2>
            <TestmonialsSlider />
          </div>
        </GridTwo>
      </Container>

      <Container noMargin className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <H2 className="mb-5 font-display">Stressmanagement: 3 Tipps für mehr Energie</H2>
            <P className="mb-3">
              Unser Arbeits- und Privatleben wird immer stressreicher. Das zeigen die Zahlen der gesetzlichen Krankenkassen. Die auf Stress zurückzuführenden Fehlzeiten am Arbeitsplatz steigen extrem. Stress, Leistungsdruck und Erschöpfung
              ziehen sich dabei durch alle Berufsgruppen und beschränken sich längst nicht mehr auf die Arbeit, sondern greift auch auf das Privatleben über.
            </P>
            <P>
              Mangelnder Ausgleich oder ein schwaches Stressmanagement führen in die Stressspirale. Auf dieser Seite möchten wir dich dabei unterstützen, dein Selbst- und Stressmanagement zu reflektieren. Damit das gelingt verschaffen wir
              dir einen Überblick über das Thema und geben dir einfache Tipps mit auf den Weg, die du sofort in deinen Alltag integrieren kannst. Also los gehts!
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Stressmanagement was ist das?</H2>
            <P className="mb-3">
              Stressmanagement wird häufig dann interessant, wenn wir an unsere Grenzen stoßen. Denn das ist der Zeitpunkt an dem wir merken, dass die Anforderungen unsere Ressourcen übersteigen. Zwangsläufig führt das zu einer
              Überforderung oder einer Überspannung. Ein Beispiel aus der Materiallehre hilft uns, Stress besser zu verstehen.
            </P>
            <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1642531714/kunden/entspannungshelden/Stresssmanagement_lfue3s.mp4" image={data.videoCover} alt="Stressmanagement Video" />
          </div>
          <div>
            <H2 className="mb-5 font-display">Stressmanagement als Alltagstool</H2>
            <P className="mb-3">
              Vielleicht fühlst du dich durch dieses Beispiel an deinen Arbeits- oder Familienalltag erinnert und bemerkst jetzt, dass es natürliche Grenzen von Belastung gibt. Übersteigt eine Anforderung unsere Ressourcen, kommt es zur
              Überlastung. Zwar brichst du nicht gleich durch, wie ein Streichholz, doch du merkst dass deine Energie erschöpft ist.
            </P>
            <P className="mb-3">
              Diese Tage kennst du genauso gut wie ich und gehören zum Leben dazu. Problematisch wird es, wenn ein Großteil deiner Woche so aussieht. Schließlich würdest du in diesem Zustand kaum ins Fitnessstudio gehen, geschweige denn dir
              eine gesunde Mahlzeit kochen. Genauso wenig hast du Lust auf soziale Kontakte oder den Kinobesuch mit deiner Freundin. Schlussendlich gerätst du in ein Energiedefizit, weil du viel mehr schlafen müsstest, als der Wecker
              zulässt. Durch mangelnde Konzentration passieren mehr Fehler und bekommst im schlimmsten Falle noch einen drauf. Dabei gibst du doch dein bestes.{' '}
            </P>
            <P className="mb-3">
              Erschöpfung, entstanden aus dem Ungleichgewicht aus Anforderung und Ressource zieht sich schleichend durch deine Lebensbereiche und betoniert dich dort ein, wo du gerade bist. Um dich herum steigt dir Wasser bis zum Hals und
              du drohst zu unterzugehen. Nicht umsonst stieg die Anzahl der Burnout Diagnosen und psychischen Erkrankungen rasant an. Um den Teufelskreis der Stressspirale zu durchbrechen und dich wieder in deine Energie und Gesundheit zu
              holen, ist Stressmanagement wichtig.{' '}
            </P>
            <P className="mb-3">
              Stressmanagement umfasst Methoden, die bei der Stressbewältigung helfen und dich befähigen, Stress vorzubeugen bzw. abzubauen. In unterschiedlichen Ebenen wendet sich das Stressmanagement den Stressauslösern, also Situationen,
              Umstände oder Gegebenheiten zu, aber auch dem Umgang damit. Doch häufig sind es nicht nur äußere Stressoren die uns im wahrsten Sinne „stressen“. Eigene Antreiber, Ansprüche und Glaubenssätze zu hinterfragen ist ein ebenso
              wichtiger Teil eines guten Programms, wie auch die Entspannung selbst.{' '}
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Vorteile eines guten Stressmanagements</H2>
            <FeatureList
              items={[
                { text: 'Unterstützt individuelle Stresspotentiale zu erkennen' },
                { text: 'Befähigt Konflikte und Belastungen zu bewältigen' },
                { text: 'Beinhaltet Strategien aus Selbst- und Zeitmanagement' },
                { text: 'Leistungsfähigkeit und Konzentration trotz hoher Anforderungen' },
                { text: 'Körperliches, psychisches Wohlbefinden und gute Regeneration' },
                { text: 'Bewusster Umgang mit der eigenen Zeit' },
                { text: 'Kluge Priorisierung und effizienteres Arbeiten' },
                { text: 'Mehr Freiräume für eigene Bedürfnisse' }
              ]}
              className="mb-3 grid grid-cols-1 gap-5 sm:grid-cols-2"
            />
            <P>Du siehst es lohnt sich deine Fähigkeiten im Bereich Stressmanagement auszubilden und zu fördern. Damit dir das ab sofort gelingt, haben wir unsere drei Lieblingstipps für dich zusammengefasst</P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Lieblingstipp Nr 1: Zeitmanagement</H2>
            <P>
              Dein Tag hat 24 Stunden. Nutze sie für das wesentliche. Die Eisenhower Strategie hilft dir, dich auf die wichtigsten Dinge zu fokussieren. Schreibe dir eine To-Do Liste mit den drei wichtigsten Dingen für den Tag. Erledige
              dann Nr 1. sofort. Zu welcher Zeit bist du am konzentriertesten? Richte dir eine Störungsfreie Stunde ein. Stell das Telefon aus, nimm dir Türklinke aus der Tür und leite Telefon und Email um. Arbeite dann 45 Minuten an deinem
              wichtigsten Auftrag und mache 5 Minuten Pause, wiederhole dieses Schema solange, bis du fertig bist.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Lieblingstipp Nr 2: Bedürfnisse erfüllen</H2>
            <P>
              Damit du dich nicht im Beispiel oben wiederfindest, solltest du auf eine hohe Lebensqualität achten. Die erreichst du am ehesten, in dem du deine Bedürfnisse wahrnimmst und erfüllst. Das bedeutet ganz konkret, das du Dinge
              unternimmst die dir gut tun. Sage Nein, wann immer du nicht mit ganzem Herzen dabei bist und setze klare Grenzen. Beachte hierbei deine Lebensbereiche: Beruf, Beziehung, Gesundheit und Sinn.
            </P>
          </div>
          <Cta
            headOne="Bist Du bereit für"
            headTwo="Entspannung?"
            button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />}
            withoutContainer
          />
          <div>
            <H2 className="mb-5 font-display">Lieblingstipp Nr 3: Entspannung und Bewegung</H2>
            <P className="mb-3">
              Dein Körper liebt den Wechsel aus An- und Entspannung. Daher achte auf ein gesundes Gleichgewicht aus Arbeit und Pause. Versuche Entspannungsübungen wie Traumreisen, Autogenes Training oder Progressive Muskelentspannung in
              deinen Alltag zu integrieren und gewöhne dir an, regelmäßig Sport zu treiben. Das sorgt für ein konstantes und hohes Energieniveau.
            </P>
            <P>
              Damit es dir gelingt diese und viele andere Dinge umzusetzen, haben wir ein Kursformat für dich entwickelt, dass du ganz einfach und bequem von zu Hause für dich nutzen kannst. In unserem Onlinekurs Stressmanagement, gehen wir
              auf all die genannten Dinge ein und vermitteln dir Methoden, die es dir ermöglichen in deiner Energie zu bleiben. So dass du motiviert in den Tag startest und auch nach Feierabend noch genug Energie hast um Dinge zu tun, die
              dir wichtig sind.
              <i>Aktuell befindet sich unser Kurs im Zertifizierungsprozess, daher wird dieses Kursformat noch nicht von den gesetzlichen Krankenkassen gefördert. </i>
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Drei Formen von Stressmanagement</H2>
            <P className="mb-3">
              Die erste Form von Stressmanagement nennt sich <strong>instrumentelles Stressmanagement</strong> und zielt darauf ab Stressauslöser zu verändern, oder abzustellen. Arbeitest du mit dieser Form, reflektierst du deinen Alltag
              und überprüfst welche Dinge dich zur Weißglut bringen und suchst nach Lösungen für diese <strong>Stressoren</strong>. Zu erwähnen sind in dieser Form die unzähligen Möglichkeiten aus dem Selbst- und Zeitmanagement. Wenn du
              mehr über Stressoren erfahren möchtest, dann hör doch mal in unseren Podcast rein!
            </P>
            <Button to="/podcast/folgen/15/werde-zum-stressdetektiv/" text="Zur Podcast-Folge" />
          </div>
          <div>
            <H2 className="mb-5 font-display">Mentales Stressmanagement</H2>
            <P>
              Jede Form der Persönlichkeitsentwicklung ist auch eine Form des Kognitiven Stressmanagements. Denn es geht darum, die eigene Persönlichkeit besser zu verstehen, zu hinterfragen Veränderungen im Denken, Fühlen und Handeln zu
              ermöglichen. Zu der klassischen Arbeit dieser Form zählt das Auflösen negativer Glaubenssätze oder Gedankenmuster, aber auch die Reflexion der eigenen Haltung, Werte und Ansprüche.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Regeneratives Stressmanagement</H2>
            <P className="mb-3">
              Stress gänzlich zu reduzieren ist nicht möglich und auch garnicht das Ziel. Daher lernst du in unserem Onlinekurs auch Methoden der Entspannung kennen, die es dir ermöglichen, Stressreaktionen zu reduzieren und abzubauen.
              Hierzu zählen unteranderem die Progressive Muskelentspannung und das Autogene Training.
            </P>
            <P>
              Stressmanagement wirkt auf unterschiedlichen Ebenen und stärkt unsere Widerstandskraft gegen situativen Stress (Stressresistenz). Methoden und Strategien zählen zur Stresskompetenz und ermöglichen uns einen günstigen Umgang
              mit Stress. Schlussendlich befähigt dich die Stressprävention und Entspannung dazu, stressreiche Situationen zu vermeiden und vorhandenen Stress abzubauen. Diese Elemente sind Grundbausteine in unserem Stressmanagement
              Onlinekurs.
            </P>
          </div>
        </div>
      </Container>
      <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} />
      <Container>
        <GridTwo>
          <div>
            <H2 className="mb-5 font-display">Impressionen aus dem Kurs:</H2>
            <GallerySlider images={data.gallery.nodes} totalcount={data.gallery.totalCount} alt="Stressmanagement Onlinekurs" />
          </div>
          <div>
            <H2 className="mb-5 font-display">Häufige Fragen zu Stressmanagement:</H2>
            <dl className="space-y-3">
              {faq.map((faq) => {
                return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
              })}
            </dl>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildKurs: file(relativePath: { eq: "course-images/stressmanagement.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    kurse(slug_slug: { eq: "stressmanagement-onlinekurs" }) {
      remoteKurseImage {
        url
        childImageSharp {
          gatsbyImageData
        }
      }
      frequenz
      price
      link
      title
      desc
      features {
        value
        field {
          label
        }
      }
    }

    bildJohannes: file(relativePath: { eq: "pages/kurse/stressmanagement/stressmanagement_dein-held-johannes.png" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }

    videoCover: file(relativePath: { eq: "video/stressmanagement-kurzuebung_cover.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "pages/kurse/stressmanagement/gallery" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.8)
        }
        id
      }
      totalCount
    }
  }
`;
